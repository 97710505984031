/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CoverContainer,
  FormContainer,
  InfoContainer,
  PageContainer,
  PageContent,
} from "./styles";

import {
  Box,
  Button,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { isMobile } from 'react-device-detect';

import logoServemei from "../../assets/Logo-Servemei.png";
import apple from "../../assets/app_store.png";
import google from "../../assets/googleplay.png";
import { HeaderContainer } from "../../components/Header/styles";
import ButtonWhatsapp from "../../components/ButtonWhatsapp";
import { cnpjMask } from "../../utils/format";
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "../../themes/colors";
import { useAnnualDeclaration } from "../../store/annualDeclaration";
import React from "react";
import CircularIndeterminate from "../../components/loading/loading";
import { useConsultaCnpj } from "../../store/annualDeclaration/useConsultaCnpj";

export function Home() {
  const { register, handleSubmit, formState, getValues } = useForm();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cnpj = queryParams?.get('cnpj');
  const cnpjUnformat = cnpj?.replace(/\D/g, '')

  const [open, setOpen] = useState(false);
  const [messageError, setMessageError] = useState("Houve um error ao processar o pagamento")

  const navigate = useNavigate()

  const { } = useAnnualDeclaration()

  const {
    mutateAsync: consultaMutationAsync,
    isLoading: isLoadingConsultaMutation
  } = useConsultaCnpj()

  const [values, setValues] = useState({
    cnpj: "",
  });

  async function onSubmit(cnpj?: string) {
    const values = getValues();
    try {
      const cnpjSend = cnpj || values.cnpj
      const cnpjUnformat = cnpjSend.replace(/\D/g, '')
      const response = await consultaMutationAsync(cnpjUnformat)
      if (response && response.erro) {
        setMessageError("CNPJ não encontrado ou tente novamente mais tarde.")
        setOpen(true)
        return
      }

      if (response && response.success) {
        localStorage.setItem('@servemei:regularização', cnpjSend)
        navigate("regularizacao")
        return
      }

      setMessageError("CNPJ não encontrado ou tente novamente mais tarde.")
      setOpen(true)
    } catch (error) {
      setMessageError("CNPJ não encontrado ou tente novamente mais tarde.")
      setOpen(true)
    }
  }

  const getCnjpMei = () => {
    const getCnpj = localStorage.getItem('@servemei:regularização');
    if (getCnpj) onSubmit(getCnpj)
  }

  useEffect(() => {
    if (cnpjUnformat && cnpjUnformat?.length === 14) {
      onSubmit(cnpjUnformat)
    } else {
      getCnjpMei()
    }
  }, [cnpjUnformat])

  const handleChangeCpf = (event: any) => {
    const cnpjFormated = cnpjMask(event.target.value);
    setValues({ ...values, cnpj: cnpjFormated });
  };

  if (isLoadingConsultaMutation) {
    return (
      <React.Fragment>
        <HeaderContainer style={{ background: "white" }}>
          <img src={logoServemei} alt="" />
        </HeaderContainer>
        <CircularIndeterminate />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <HeaderContainer style={{ background: "white" }}>
        <img src={logoServemei} alt="" />
      </HeaderContainer>
      <PageContainer>
        <PageContent style={!isMobile ? { height: "35rem" } : undefined}>
          <FormContainer>
            {!isMobile && (
              <InfoContainer>
                <Typography style={{ fontFamily: 'Lato' }} variant="h4" textTransform={"uppercase"}>
                  MEI: faça sua Declaração Anual com toda segurança aqui
                </Typography>
                <Typography variant="subtitle1" style={{ fontFamily: 'Lato' }}>
                  A Declaração Anual do MEI (DASN-SIMEI) deve ser entregue por todos que tem um CNPJ MEI e que abriram ele até o dia 31 de Dezembro de 2023 ou em anos anteriores, mesmo os que não movimentaram nada de dinheiro.
                </Typography>
              </InfoContainer>
            )}
            <div>
              <div
                style={{
                  padding: "2rem",
                  height: "auto",
                  color: "#1E1F20",
                  borderRadius: 8,
                  background: "white",
                }}
              >
                <Box sx={{ mb: 6 }}>
                  <Typography style={{ fontFamily: 'Lato' }} variant="h5">
                    Fique em dia! Consulte GRÁTIS e entregue sua Declaração Aqui.
                  </Typography>

                  <Typography style={{ fontFamily: 'Lato' }} marginTop={1} justifyContent={"center"}>
                    Para você consultar se tem declarações pendentes ou realizar a entrega com toda segurança e praticidade, é só inserir no campo abaixo o seu CNPJ. Caso não saiba qual é fale com nossa equipe de suporte usando bo botão do whatsApp no canto inferior da tela.
                  </Typography>
                </Box>
                <form autoComplete="off" onSubmit={handleSubmit(() => onSubmit())}>
                  <TextField
                    {...register("cnpj", {
                      maxLength: 18,
                      minLength: 18,
                    })}
                    required
                    fullWidth
                    id="cnpj"
                    name="cnpj"
                    value={values.cnpj}
                    onChange={(event) => handleChangeCpf(event)}
                    label="CNPJ"
                    error={formState.errors?.cnpj ? true : false}
                    inputProps={{
                      maxLength: 18,
                      minLength: 18,
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    sx={{ marginBottom: 4 }}
                  />
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    disabled={isLoadingConsultaMutation}
                    sx={{ marginBottom: 2, borderRadius: 4 }}
                    type="submit"
                    style={{ fontFamily: 'Lato', backgroundColor: COLORS.amareloServemei }}
                  >
                    Fazer declaração anual (DASN)
                  </Button>
                </form>
              </div>
            </div>
            {isMobile && (
              <InfoContainer>
                <Typography style={{ fontFamily: 'Lato' }} variant="h4" textTransform={"uppercase"}>
                  MEI: faça sua Declaração Anual com toda segurança aqui
                </Typography>
                <Typography variant="subtitle1" style={{ fontFamily: 'Lato' }}>
                  A Declaração Anual do MEI (DASN-SIMEI) deve ser entregue por todos que tem um CNPJ MEI e que abriram ele até o dia 31 de Dezembro de 2023 ou em anos anteriores, mesmo os que não movimentaram nada de dinheiro.
                </Typography>
              </InfoContainer>
            )}
          </FormContainer>
        </PageContent>
        <CoverContainer>
          <Typography style={{ marginTop: "10rem", fontFamily: "Lato" }} variant="h5">
            Transforme seu Negócio sem Gastar Nada!
          </Typography>
          <Typography fontWeight="Bold" color="#edc42e" variant="h4" style={{ fontFamily: 'Lato' }}>
            Descubra a Versão Gratuita do Super Aplicativo para Empreendedores!
          </Typography>
          <Typography variant="body2" style={{ fontFamily: 'Lato' }}>
            Liberte seu Potencial Empreendedor! Abra as Asas do seu Negócio com
            a Versão Gratuita do Super Aplicativo. Contrate Nossa Assessoria
            Quando Desejar, no seu Próprio Ritmo. Nós Valorizamos sua Autonomia
            e Queremos Ver Você Prosperar. Experimente Agora e Desfrute da
            Liberdade de Empreender com Confiança.
          </Typography>
          <div>
            <Link href="https://apps.apple.com/br/app/servemei/id1545707174">
              <img src={apple} />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.servemei">
              <img src={google} />
            </Link>
          </div>
        </CoverContainer>
        <ButtonWhatsapp />
      </PageContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        message={messageError}
        key={"top" + "left"}
      />
    </React.Fragment>
  );
}

