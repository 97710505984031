import { useQuery } from '@tanstack/react-query';
import api from '../../service/api';

export function useAnnualDeclaration() {
  const getServerList = async () => {
    const { data } = await api.get('/mei/configs');

    if (data?.AnnualDeclaration?.value) {
      return data.AnnualDeclaration.value;
    } else {
      return 49.0;
    }
  };

  return useQuery(['GET_CONFIGS_MEI'], getServerList, {
    refetchInterval: 1 * 1000 * 60 * 2, // 2 minutes
  });
}
