import { Typography } from '@mui/material';
import { COLORS } from './../../themes/colors';
import styled from "styled-components";

export const PageContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 7px;
`;

export const CoverContainer = styled.div`
  width: 95%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 4rem;
  margin-top: -2.5rem;
  border-radius: 20px;
  background: ${COLORS.servemei};
  color: #f4f4f4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: 18rem;
  z-index: -1;
  text-align: center;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 5rem;

    > a {
      cursor: pointer;

      img {
        width: 10rem;
      }
    }
  }
`;

export const ResultClient = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: -5rem;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 1px 2px 5px 0px #C1C3C5;
`;

export const WrapperContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const TypographyTitle = styled(Typography).attrs({
    fontSize: "29px",
    fontFamily: "Lato",
    maxWidth: "70%"
})`
  @media (max-width: 900px) {
    font-size: 21px !important;
  }
`;

export const HelpContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-block: 3rem;

  img {
    width: 14rem;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
  
`

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
`

