import { useMutation } from '@tanstack/react-query';
import api from '../../service/api';
import queryClient from '../queryClient';

export const LIST_YEARS = "LIST_YEARS"

export function useConsultaCnpj() {
  const mutationFn = async (cnpj: string) => {
    const cnpjUnformat = cnpj.replace(/\D/g, '')

    const { data } = await api.get(`/yearlyDeclaring/ConsultaCNPJ/${cnpjUnformat}`);
    console.log("aqui",)
    if (data?.years?.length <= 0) {
      return {
        success: false,
        erro: true,
        message: "CNPJ não encontrado. Tente novamente mais tarde."
      }
    }

    queryClient.setQueryData([LIST_YEARS], () => data);

    return {
      success: true,
      erro: false,
      message: "",
    }
  };

  return useMutation({
    mutationKey: ["GET_CONSULTA_CNPJ"],
    mutationFn,
  });
}
