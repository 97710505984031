export const env = 'production';

const endpointsByEnv = {
  production: {
    api: 'https://api.servemei.com.br/',
  },
  staging: {
    api: 'http://localhost:7071/',
  },
};

export const endpoints = endpointsByEnv[env] || endpointsByEnv.production;
