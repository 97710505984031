import { Button, Checkbox, Divider, FormControlLabel, Snackbar, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { formatPhone, formatPrice, maskValue } from "../../utils/format";
import { useForm } from "react-hook-form";
import React from "react";
import { COLORS } from "../../themes/colors";
import { useAnnualDeclaration } from "../../store/annualDeclaration";

const FormServices = ({ ano, onConfirmation }: {
  ano: string | null,
  onConfirmation: (values: { receitaComercio: string, receitaServico: string, informacaoEmpregado: boolean, email: string, telefone: string }) => void
}) => {
  const { register, handleSubmit, formState, getValues } = useForm();
  const [checked, setChecked] = useState<"yes" | "no">("no");
  const [confirmationChecket, setConfirmationChecket] = useState(false)

  const [colorTextError, setColorTextError] = useState("black")

  const [open, setOpen] = useState(false);
  const [messageError, setMessageError] = useState("Houve um error ao processar o pagamento")

  const {
    data: valueDeclarations,
  } = useAnnualDeclaration()

  const [values, setValues] = useState({
    receitaComercio: maskValue("0"),
    receitaServico: maskValue("0"),
    email: "",
    telefone: ""
  });

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    if (name === "telefone") {
      const valueFormated = formatPhone(event.target.value);
      setValues({ ...values, [name]: valueFormated });
      return
    }

    if (name === "email") {
      setValues({ ...values, [name]: event.target.value });
      return
    }

    const valueFormated = maskValue(event.target.value);
    setValues({ ...values, [name]: valueFormated });
  };

  const handleCheckChange = (value: "yes" | "no") => {
    setChecked(value);
  };

  const onSubmit = () => {
    if (!confirmationChecket) {
      setColorTextError("red")
      setMessageError("É preciso confirmar todas as informações citadas.")
      setOpen(true)
      return
    }

    setColorTextError("black")

    const getAllValues = getValues();
    const { receitaComercio, receitaServico, email, telefone } = getAllValues
    onConfirmation({ receitaComercio, receitaServico, informacaoEmpregado: checked === "yes", email, telefone: formatPhone(telefone) })
  }

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 23, marginBottom: 30, fontFamily: 'Lato' }}>
        Preencha os dados para realizar a declaração anual de {ano}
      </Typography>

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", marginBottom: 10, fontFamily: 'Lato' }}>
          Valor total que RECEBEU durante {ano} com vendas de produto. Inclua também receitas de transporte intermunicipal e interestadual
          e fornecimento de refeições.
        </Typography>
        <TextField
          {...register("receitaComercio")}
          required
          fullWidth
          id="receitaComercio"
          name="receitaComercio"
          value={values.receitaComercio}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeValue(event, "receitaComercio")}
          label="Receita de comércio e indústria:"
          error={formState.errors?.receitaComercio ? true : false}
          sx={{ marginBottom: 4 }}
          placeholder='R$ 0,00'
        />
        <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", marginBottom: 10, fontFamily: 'Lato' }}>
          Total que RECEBEU durante {ano} com seus serviços prestados. Inclua também
          receitas de locação e demais receitas da atividade sem incidência de
          ICMS e ISS
        </Typography>
        <TextField
          {...register("receitaServico")}
          required
          fullWidth
          id="receitaServico"
          name="receitaServico"
          value={values.receitaServico}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeValue(event, "receitaServico")}
          label="Receita de prestação de serviços:"
          error={formState.errors?.receitaServico ? true : false}
          sx={{ marginBottom: 4 }}
          placeholder='R$ 0,00'
        />

        <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", marginBottom: 10, fontFamily: 'Lato' }}>
          Possuiu empregado durante o período abrangido pela declaração ?
        </Typography>

        <div style={{ textAlign: 'left', marginTop: '10px' }}>
          <FormControlLabel
            control={<Checkbox checked={checked === "yes"} onChange={() => handleCheckChange("yes")} style={{ color: "black" }} />}
            label="Sim"
            style={{ color: "black" }}
          />

          <FormControlLabel
            control={<Checkbox checked={checked === "no"} onChange={() => handleCheckChange("no")} style={{ color: "black" }} />}
            label="Não"
            style={{ color: "black" }}
          />
        </div>

        <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", marginTop: 30, fontFamily: 'Lato', color: colorTextError }}>
          Eu confirmo que todas as informações citadas acima são
          verdadeiras e de total responsabilidade minha.
        </Typography>

        <div style={{ textAlign: 'left', marginTop: '10px' }}>
          <FormControlLabel
            control={<Checkbox checked={confirmationChecket} onChange={() => {
              setConfirmationChecket(!confirmationChecket);
              setColorTextError("black");
            }} style={{ color: colorTextError }} />}
            label="Sim"
            style={{ color: colorTextError }}
          />

          <Divider />

          <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", marginBottom: 20, fontFamily: 'Lato', marginTop: 10 }}>
            Para enviarmos o recibo e mantê-lo atualizado sobre a transmissão da sua declaração anual, precisamos do seu email e telefone (WhatsApp).
          </Typography>

          <TextField
            {...register("email", {
              required: 'Required'
            })}
            required
            fullWidth
            id="email"
            name="email"
            type="email"
            value={values.email}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeValue(event, "email")}
            label="Seu melhor e-mail"
            error={formState.errors?.email ? true : false}
            sx={{ marginBottom: 4 }}
            placeholder='email@hotmail.com'
          />

          <TextField
            {...register("telefone", {
              maxLength: 15,
              minLength: 15,
            })}
            required
            fullWidth
            id="telefone"
            name="telefone"
            value={values.telefone}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeValue(event, "telefone")}
            label="Seu melhor telefone (WhatsApp)"
            error={formState.errors?.telefone ? true : false}
            sx={{ marginBottom: 4 }}
            placeholder='(99) 99999-9999'
            inputProps={{
              maxLength: 15,
              minLength: 15,
            }}
          />

          <Divider />

          <Typography variant="h5" style={{ fontSize: 17, textAlign: "left", marginTop: 30, fontFamily: 'Lato' }}>
            Valor total:
          </Typography>
          <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontFamily: 'Lato', color: COLORS.servemei, fontWeight: "bold" }}>
            {formatPrice(valueDeclarations || 47)}
          </Typography>
          <Typography variant="h5" style={{ fontSize: 14, textAlign: "left", fontFamily: 'Lato' }}>
            Pagamento no Pix ou Cartão de Crédito
          </Typography>
        </div>

        <Button
          fullWidth
          size="large"
          variant="contained"
          sx={{ marginBottom: 2, marginTop: 2, borderRadius: 4 }}
          type="submit"
          style={{ fontFamily: 'Lato', backgroundColor: COLORS.amareloServemei }}
        >
          Finalizar declaração anual (DASN)
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        message={messageError}
        key={"top" + "left"}
      />
    </React.Fragment>
  )
}

export default FormServices;