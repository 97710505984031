import { Button, Grid, TextField, Typography } from '@mui/material';
import { formatCPF, formatExpiry, formatPrice, mcc } from '../../utils/format';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { COLORS } from '../../themes/colors';
import { useAnnualDeclaration } from '../../store/annualDeclaration';

interface Comprador {
  documento: string;
}

interface Pagamento {
  valor: number;
  parcelas: number;
}

interface CartaoCredito {
  numeroCartao: string;
  titular: string;
  dataExpiracao: string;
  codigoSeguranca: string;
}

export interface CardCredits {
  cardCredit: {
    comprador: Comprador;
    pagamento: Pagamento;
    cartaoCredito: CartaoCredito;
  }
}

const CardCredit = ({ onConfirmation, loading }: {
  onConfirmation: (values: CardCredits) => void,
  loading: boolean
}) => {
  const { register, handleSubmit, formState, getValues } = useForm();

  const [values, setValues] = useState({
    numeroCartao: '',
    titular: '',
    dataExpiracao: '',
    codigoSeguranca: '',
    cpf: ''
  });

  const {
    data: valueDeclarations,
  } = useAnnualDeclaration()

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target

    if (name === 'numeroCartao') {
      const retorno = mcc(value)
      setValues({ ...values, [name]: retorno })

      return
    }

    if (name === 'dataExpiracao') {
      const retorno = formatExpiry(value)
      setValues({ ...values, [name]: retorno })

      return
    }

    if (name === 'cpf') {
      const retorno = formatCPF(value)
      setValues({ ...values, [name]: retorno })

      return
    }

    setValues({ ...values, [name]: value })
  }

  const onSubmit = () => {
    const getAllValues = getValues();

    const {
      numeroCartao,
      titular,
      dataExpiracao,
      codigoSeguranca,
      cpf
    } = getAllValues

    const cardCredit = {
      comprador: {
        documento: cpf.replace(/\D/g, ''),
      },
      pagamento: {
        valor: valueDeclarations || 47,
        parcelas: 1,
      },
      cartaoCredito: {
        numeroCartao,
        titular,
        dataExpiracao,
        codigoSeguranca,
      },
    };

    onConfirmation({cardCredit})
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <Typography
        gutterBottom
        sx={{ display: 'flex', justifyContent: 'center', fontSize: 14, fontFamily: "Lato", marginBottom: 2 }}
      >
        Insira os dados do cartão
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TextField
            id='outlined-basic'
            {...register('titular', {
              required: 'Required'
            })}
            label='Nome do titular *'
            variant='outlined'
            name='titular'
            error={formState.errors?.titular ? true : false}
            fullWidth
            value={values.titular}
            onChange={handleChange}
            inputProps={{
              style: {
                textTransform: 'uppercase',
                fontFamily: "Lato"
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TextField
            id='outlined-basic'
            {...register('cpf', {
              required: 'Required',
              maxLength: 14,
              minLength: 14,
            })}
            label='CPF*'
            variant='outlined'
            name='cpf'
            error={formState.errors?.cpf ? true : false}
            fullWidth
            value={values.cpf}
            onChange={handleChange}
            inputProps={{
              maxLength: 14,
              minLength: 14,
              style: {
                textTransform: 'uppercase',
                fontFamily: "Lato"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            id='outlined-basic'
            {...register('numeroCartao', {
              required: 'Required',
              maxLength: 19,
              minLength: 19,
            })}
            label='Número do cartão *'
            variant='outlined'
            sx={{ width: '100%' }}
            name='numeroCartao'
            error={formState.errors?.numeroCartao ? true : false}
            value={values.numeroCartao}
            onChange={handleChange}
            inputProps={{
              maxLength: 19,
              minLength: 19,
              style: {
                textTransform: 'uppercase',
                fontFamily: "Lato"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id='outlined-basic'
                {...register('codigoSeguranca', {
                  required: 'Required',
                  minLength: 3,
                  maxLength: 3
                })}
                label='CVV*'
                variant='outlined'
                name='codigoSeguranca'
                error={formState.errors?.codigoSeguranca ? true : false}
                value={values.codigoSeguranca}
                onChange={handleChange}
                inputProps={{
                  minLength: 3,
                  maxLength: 3,
                  style: {
                    textTransform: 'uppercase',
                    fontFamily: "Lato"
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='outlined-basic'
                {...register('dataExpiracao', {
                  required: 'Required',
                  maxLength: 7,
                  minLength: 7
                })}
                label='Válido até (mm/aaaa) *'
                variant='outlined'
                name='dataExpiracao'
                error={formState.errors?.dataExpiracao ? true : false}
                fullWidth
                value={values.dataExpiracao}
                onChange={handleChange}
                inputProps={{
                  maxLength: 7,
                  minLength: 7,
                  style: {
                    textTransform: 'uppercase',
                    fontFamily: "Lato"
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Button
        fullWidth
        disabled={loading}
        size="large"
        variant="contained"
        sx={{ marginBottom: 2, marginTop: 5, borderRadius: 4 }}
        type="submit"
        style={{ fontFamily: 'Lato', backgroundColor: COLORS.amareloServemei }}
      >
        Pagar {formatPrice(valueDeclarations || 47)}
      </Button>
    </form>
  )
}

export default CardCredit;