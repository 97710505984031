import React from 'react'
import { QueryClientProvider } from "@tanstack/react-query"
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import queryClient from './store/queryClient.ts'
import MetaPixel from "../MetaPixel.tsx"

import { GlobalStyle } from "./theme/global";

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <MetaPixel />
      <div onContextMenu={(event) => event.preventDefault()}>
        <App />
      </div>
    </QueryClientProvider>
  </React.StrictMode>,
)
