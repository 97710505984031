import { Snackbar, Typography } from "@mui/material";
import { InfoContainer, InfoContent, ContainerName, ContainerButton } from "./styles";
import { WarningCircle, CheckCircle } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import useYears, { Item } from "../../store/annualDeclaration/useYears";

import "./list.styles.css"
import { useState } from "react";

// interface propsDate {
//   dados: Array<{
//     ano: string;
//     status: string;
//     description: string;
//   }>;
// }

interface propsUser {
  loading: boolean;
}

// interface propsItem {
//   ano: string
//   status: string
//   description: string
//   issuedByServemei?: boolean
//   receiptUrl?: string | null
//   darfeMultaUrl?: string | null
//   notificationUrl?: string | null
// }

// interface PropsUrl {
//   receiptUrl: string | null
//   darfeMultaUrl: string | null
//   notificationUrl: string | null
// }

export function InfoPendencia(params: propsUser) {
  const { loading } = params

  const [open, setOpen] = useState(false);
  const [messageError] = useState("Antes de prosseguir, você precisa transmitir a declaração do ano anterior.");


  const {
    data: annualDeclarationYears,
  } = useYears();

  const navigate = useNavigate()

  const navigationPayment = (params: Item) => {
    console.log(params.description)
    if (params.description === "Não Optante") return;

    if (params.transmitida === false && annualDeclarationYears?.years?.length) {
      const allPending =  annualDeclarationYears?.years?.filter(item => item.transmitida === false);
      if (allPending.length > 1) {
        const anosOrdenados = allPending.sort((a, b) => Number(a.ano) - Number(b.ano))[0]
        if (anosOrdenados.ano !== params.ano) {
          setOpen(true)
          return
        }
      }
    }


    localStorage.setItem('@servemei:ano:regularizacao', `${params.ano}`)
    navigate(`/payments`);
  }



  // console.log(DefinirStatusPorAno(annualDeclarationYears?.years || []))
  // const receiptDawloads = (params: PropsUrl) => {
  //   if (params.receiptUrl) {
  //     window.open(params.receiptUrl, '_blank');
  //   }

    // if (params.darfeMultaUrl) {
    //   window.open(params.darfeMultaUrl, '_blank');
    // }

    // if (params.notificationUrl) {
    //   window.open(params.notificationUrl, '_blank');
    // }
  //}


  return (
    <>
      <InfoContainer>
        {annualDeclarationYears?.years?.map((item: Item) => {
          return (
            <InfoContent key={item.ano}  onClick={() => navigationPayment(item)}>
              <ContainerName>
                {item.transmitida === true ? (
                  <CheckCircle size={22} color="green" />
                ) : item.transmitida === false ? <WarningCircle size={22} color="red" /> : <CheckCircle size={22} color="#999999" />}
                <Typography style={{ fontFamily: 'Lato', fontWeight: "bold", marginLeft: 2 }}>{item.ano}</Typography>
              </ContainerName>
              <ContainerButton style={{ fontFamily: 'Lato' }} color={item.transmitida === true ? "green" : item.transmitida === false ? "red" : "#A8A8B7"}
                onClick={() => navigationPayment(item)}>
                {loading ? (
                  <span>Consultando ...</span>
                ) : (
                  <span>
                      {item.description?.startsWith("Não Optante") && "Não optante"}
                      {item.description?.startsWith("apresentada em") && "Retificar"}
                      {item.description?.startsWith("ano anterior") && "Declarar"}
                      {item.description?.startsWith("não apresentada") && "Declarar"}
                  </span>
                )}
              </ContainerButton>

              {item.description?.startsWith("apresentada em") && (
                <div className="list-date-payment">
                  <text className="text-date-payment">{item.description?.toUpperCase()}</text>
                </div>
              )}
                {item.transmitida === false && (
                <div className="list-date-payment">
                  <text className="text-date-payment">DATA LIMITE: 31/05/{Number(item.ano) + 1}</text>
                </div>
              )}
              {/* {item?.issuedByServemei && (
              <button onClick={() => receiptDawloads({
                receiptUrl: item?.receiptUrl ?? null,
                darfeMultaUrl: item?.darfeMultaUrl ?? null,
                notificationUrl: item?.notificationUrl ?? null,
              })} style={{ position: "absolute", marginLeft: "23.2%", border: "1px solid #99999955", padding: 7, borderRadius: 50, backgroundColor: "#fff" }}>
                <FilePdf size={18} color={COLORS.servemei} />
              </button>
            )} */}

            </InfoContent>
          )
        })}


      </InfoContainer>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        message={messageError}
        key={"top" + "left"}
      />
    </>
  );
}
