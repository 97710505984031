import { Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../themes/colors';

const Footer: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', padding: "20px", backgroundColor: COLORS.servemei, width: "100%", justifyContent: "center", alignItems: "center", height: 140, display: "flex", flexDirection: "column", marginTop: "auto"}}>
      <Typography style={{ color: COLORS.white, fontSize: 14, fontFamily: 'Lato' }}>
        SERVEMEI TECNOLOGIA E ASSESSORIA LTDA - CNPJ 52.486.940/0001-36
      </Typography>
      <Typography style={{ color: COLORS.white, fontSize: 14, fontFamily: 'Lato' }}>
        contato@servemei.com.br | Aracaju - Sergipe
      </Typography>
    </div>
  )
}

export default Footer;