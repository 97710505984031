import styled from "styled-components";
import { COLORS } from "../../themes/colors";
import { isMobile } from 'react-device-detect';

export const PageContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const PageContent = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FormContainer = styled.div`
  width: 90%;
  min-height: 30rem;
  height: 100%;
  overflow:hidden;
  z-index: 2;
  background: ${COLORS.servemei};
  border-radius: 8px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2.5rem;
  text-align: center;
  padding: 2rem;
  color: white;
  border: 1px solid white;

  @media (max-width : 1000px) {
    flex-direction: column;
    height: auto;
    margin-top: ${isMobile ? "0rem" : "23rem"};
    width: 98%;
    padding: 5px;
  }

  > div {
    width: 100%;
  }
`
export const InfoContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 1rem;
   flex-direction: column;
   padding: 1rem;

`

export const CoverContainer = styled.div`
  background: ${COLORS.servemei};
  background-position: center;
  background-size: cover;
  background-Repeat: no-repeat;
  height: 100%;
  margin-top: -8rem;
  position: relative;
  bottom: 0;
  right: 0;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: 'center';
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  text-align: center;
  width: 100%;

  @media (max-width : 1000px) {
    margin-top:  ${isMobile ? "-4rem" : "14rem"};
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 5rem;

    > a {
       cursor: pointer; 

        img {
            width: 10rem;
            height: 50px;
        }
    }
  }
`
