import { useMutation } from "@tanstack/react-query";
import { ApiError, PaymentData, MutationResponse } from "./types";
import api from '../../service/api';

export const FINISH_DO_PAYMENT = "FINISH_DO_PAYMENT"

export function useDoPayment() {
  const mutationFn = async (paymentData: PaymentData): Promise<{keyPix: string, idPix: number}> => {
    try {
      const { data } = await api.post<MutationResponse>('/yearlyDeclaring/pixPayment/create', paymentData);

      const { keyPix, idPix } = data;

      return { keyPix, idPix };
    } catch (error) {
      throw error as ApiError;
    }
  };

  return useMutation<{keyPix: string, idPix: number}, ApiError, PaymentData>({
    mutationKey: [FINISH_DO_PAYMENT],
    mutationFn,
  });
}