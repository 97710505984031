import { useQuery } from '@tanstack/react-query';
import { LIST_YEARS } from './useAnnualDeclarationYears';

export interface Item {
  ano: string;
  TipoSituacao: string;
  description: string;
  transmitida: boolean;
  DataTransmissao: string | null;
  status: string;
}

export interface PropsItem {
  years: Item[]
  user: User;
}

export interface User {
  nomeEmpresarial: string;
  situacaoCadastral: string;
  cnpj: string;
  abertura: string;
  anoAbertura: number
}

export default function useYears() {
  const queryFn = async () => ({} as PropsItem);

  return useQuery<PropsItem>({
    queryFn,
    queryKey: [LIST_YEARS],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
  });
}
