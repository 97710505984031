/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardContent, Container, Divider, Grid, TextField, Typography, Snackbar, CircularProgress } from '@mui/material';
import Header from '../../components/Header/header';
import { useAnnualDeclaration, useDoPayment, useGetPixConfirmation } from '../../store/annualDeclaration';
import { COLORS } from '../../themes/colors';
import Footer from '../../components/Footer/footer';
import { formatPrice } from '../../utils/format';

import QRCode from 'qrcode.react'

import { CheckCircle } from "phosphor-react";

import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Countdow from '../../components/countdow/countdow';
import Timeline from '../../components/timeline/timeline';
import FormServices from '../../components/formServices/formServices';
import { useDoPaymentCredit } from '../../store/annualDeclaration/useCreatePaymentCredit';
import CardCredit, { CardCredits } from '../../components/cardCredit/cardCredit';
import CircularIndeterminate from '../../components/loading/loading';

interface PropsForm {
  receitaComercio: string;
  receitaServico: string;
  informacaoEmpregado: boolean;
  email: string;
  telefone: string
}

export function Payment() {
  const [ano, setAno] = React.useState<string | null>(null);
  const [cnpj, setCnpj] = React.useState<string>("")
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false);
  const [messageError, setMessageError] = useState("Houve um error ao processar o pagamento")
  const [keyPixValue, setkeyPixValue] = useState("")
  const [idPix, setIdPix] = useState<number | null>(null)

  const [values, setValues] = useState<PropsForm>({
    receitaComercio: "",
    receitaServico: "",
    informacaoEmpregado: false,
    email: "",
    telefone: ""
  })

  const {
    data: valueDeclarations,
  } = useAnnualDeclaration()

  const {
    mutateAsync: createPaymentsMutationAsync,
    isLoading: loadingCreatePayments
  } = useDoPayment()

  const {
    mutateAsync: createPaymentsCreditMutationAsync,
    isLoading: loadingCreatePaymentsCredit,
    reset: resetCreatePaymentsCredit
  } = useDoPaymentCredit()

  const {
    data: keyPixValueConfirmation,
  } = useGetPixConfirmation(idPix)

  const onCopyPix = () => {
    if (!keyPixValue) return
    const el = document.createElement('textarea')
    el.value = keyPixValue
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    setMessageError("Código Pix Copiado com sucesso")
    setOpen(true)
  }

  React.useEffect(() => {
    const getAnoProcessPayment = () => {
      const getAno = localStorage.getItem('@servemei:ano:regularizacao');
      const getCnpj = localStorage.getItem('@servemei:regularização');

      if (!getAno || !getCnpj) {
        window.location.href = '/'
        return
      }

      setCnpj(getCnpj || "")
      setAno(getAno)
    }
    getAnoProcessPayment()
  }, [])

  useEffect(() => {
    if (keyPixValueConfirmation && keyPixValueConfirmation?.status === 'Pago') {
      setIdPix(null)
      setkeyPixValue("")
      setStep(5)
    }
  }, [keyPixValueConfirmation])

  const renderFooterV2 = useCallback(
    () => {
      return <Footer />
    },
    [step]
  )

  const unformatPrice = (value: string) => {
    return value
      .replace('R$ ', '')
      .replace(' ', '')
      .replace(',', '.');
  }

  const createPaymentsPix = async () => {
    if (loadingCreatePayments || !ano || !cnpj) return;

    try {
      const response = await createPaymentsMutationAsync({
        valor: valueDeclarations || 47,
        cpfCnpjComprador: cnpj.replace(/\D/g, ''),
        descricao: 'Serviço de declaração anual mei individual.',
        mei_id: "",
        receitaComercio: unformatPrice(values.receitaComercio),
        receitaServico: unformatPrice(values.receitaServico),
        ano: Number(ano),
        informacaoEmpregado: values.informacaoEmpregado,
        typeDeclarationOn: "Original",
        isLandPage: true,
        email: values.email,
        telefone: values.telefone
      })
      setkeyPixValue(response.keyPix);
      setIdPix(response.idPix)
      setStep(3)
    } catch (error) {
      setMessageError((error as any)?.response?.data?.message || "Houve um error ao processar o pagamento")
      setOpen(true)
    }
  }

  const onConfirmation = (params: PropsForm) => {
    setValues({
      ...values,
      receitaComercio: params.receitaComercio,
      receitaServico: params.receitaServico,
      informacaoEmpregado: params.informacaoEmpregado,
      email: params.email,
      telefone: params.telefone
    })
    setStep(2)
  }

  const onConfirmationCredit = async (params: CardCredits) => {
    try {
      await createPaymentsCreditMutationAsync({
        cpfCnpjComprador: cnpj.replace(/\D/g, ''),
        mei_id: "",
        receitaComercio: unformatPrice(values.receitaComercio),
        receitaServico: unformatPrice(values.receitaServico),
        ano: Number(ano),
        informacaoEmpregado: values.informacaoEmpregado,
        typeDeclarationOn: "Original",
        isLandPage: true,
        cardCredit: {
          cardCredit: params.cardCredit
        },
        email: values.email,
        telefone: values.telefone
      })
      setStep(5)
    } catch (error) {
      resetCreatePaymentsCredit();

      setMessageError((error as any)?.response?.data?.message || "Houve um error ao processar o pagamento")
      setOpen(true)
    }
  }

  const buttonUpdateFormPayment = () => {
    return (
      <Button
        fullWidth
        size="large"
        variant="contained"
        sx={{ marginBottom: 2, marginTop: 0, borderRadius: 4 }}
        type="submit"
        style={{ fontFamily: 'Lato', backgroundColor: COLORS.white, color: COLORS.servemei, border: `1px solid ${COLORS.servemei}` }}
        onClick={() => {
          setkeyPixValue("");
          setStep(2);
        }
        }
      >
        ALTERAR FORMA DE PAGAMENTO
      </Button>
    )
  }

  if (loadingCreatePayments) {
    return (
      <React.Fragment>
        <Header loading={false} />
        <CircularIndeterminate />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Header  loading={false} />
      <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '10px', border: "1px solid #99999944", padding: "30px", borderRadius: 5, marginBottom: 50 }}>
        <Timeline step={step} />
        {step === 1 && (
          <FormServices ano={ano} onConfirmation={onConfirmation} />
        )}
        {step === 2 && (
          <React.Fragment>
            <Typography variant="h1" gutterBottom style={{ textAlign: "left", fontSize: 12, marginBottom: 0, fontFamily: 'Lato' }}>
             DASN - {ano}
            </Typography>
            <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 23, marginBottom: 30, fontFamily: 'Lato' }}>
              Selecione uma forma de pagamento
            </Typography>
            <Typography variant="h5" style={{ fontSize: 17, textAlign: "left", marginTop: 30, fontFamily: 'Lato' }}>
              Valor total:
            </Typography>
            <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontFamily: 'Lato', color: COLORS.servemei, fontWeight: "bold" }}>
              {formatPrice(valueDeclarations || 47)}
            </Typography>
            <Typography variant="h5" style={{ fontSize: 14, textAlign: "left", fontFamily: 'Lato' }}>
              Pagamento no Pix ou Cartão de Crédito
            </Typography>

            <Divider style={{ marginTop: 20 }} />

            <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 15, marginBottom: 5, fontFamily: 'Lato', marginTop: 20 }}>
              Forma de pagamento
            </Typography>
            <div style={{ flexDirection: "row", display: "flex", padding: 0, gap: 5, marginTop: 7 }}>
              <Button style={{ border: "1px solid #99999944", width: "50%", padding: 8, alignItems: "center", display: "flex", flexDirection: "row", gap: 10, borderRadius: 5, height: 60, justifyContent: "center" }} onClick={() => createPaymentsPix()}>
                <PixIcon fontSize="large" color={"primary"} />
                <Typography variant="h5" style={{ fontSize: 14, fontFamily: 'Lato', textTransform: "capitalize" }}>
                  Pix
                </Typography>
              </Button>
              <Button style={{ border: "1px solid #99999944", width: "50%", padding: 8, alignItems: "center", display: "flex", flexDirection: "row", gap: 10, borderRadius: 5, height: 60, justifyContent: "center" }} onClick={() => setStep(4)}>
                <CreditCardIcon fontSize="large" color={"primary"} />
                <Typography variant="h5" style={{ fontSize: 14, fontFamily: 'Lato', textTransform: "capitalize" }}>
                  Cartão de Crédito
                </Typography>
              </Button>
            </div>
          </React.Fragment>
        )}

        {step === 3 && (
          <React.Fragment>
            <Typography variant="h1" gutterBottom style={{ textAlign: "left", fontSize: 12, marginBottom: 0, fontFamily: 'Lato' }}>
              DASN - {ano}
            </Typography>
            <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 23, marginBottom: 20, fontFamily: 'Lato' }}>
              Pagamento em PIX
            </Typography>
            <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", fontFamily: 'Lato', marginBottom: 30 }}>
              Pix é prático e rápido, o pagamento é imediato e super seguro.
              Todos os dias e a qualquer hora. Após realizar o pagamento via PIX Copia e Cola, logo a sua Declaração será conferida e transmitida a Receita.
              Após isso enviaremos o comprovante via email e WhatsApp para você conferir.
            </Typography>

            <QRCode value={keyPixValue || ""} size={270} style={{ opacity: 1 }} />

            <Container style={{ width: "100%", marginTop: 40 }}>
              <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 13, fontFamily: 'Lato', marginBottom: 20 }}>
                1 - Copie o Código:
              </Typography>

              <TextField
                fullWidth
                value={keyPixValue}
                label="Chave Pix"
                sx={{ marginBottom: 4 }}
                disabled
              />

              <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 13, fontFamily: 'Lato', marginBottom: 20 }}>
                2 - Acesse o aplicativo ou site do seu banco e acesse a opção PIX
              </Typography>
              <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 13, fontFamily: 'Lato', marginBottom: 20 }}>
                3 - Escolha a opção PIX copia e cola
              </Typography>
              <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 13, fontFamily: 'Lato', marginBottom: 20 }}>
                4 - Cole o código, confira as informações e finalize a compra
              </Typography>

              <Divider />

              <Typography variant="h4" gutterBottom style={{ textAlign: "center", fontSize: 13, fontFamily: 'Lato', marginBottom: 20, marginTop: 10 }}>
                O Código PIX expira em:
              </Typography>

              <Countdow isActive={keyPixValue !== ""} setStep={setStep} />

              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{ marginBottom: 2, marginTop: 2, borderRadius: 4 }}
                type="submit"
                style={{ fontFamily: 'Lato', backgroundColor: COLORS.amareloServemei }}
                onClick={onCopyPix}
              >
                Copiar código PIX
              </Button>

              {buttonUpdateFormPayment()}
            </Container>
          </React.Fragment>
        )}

        {step === 4 && (
          <React.Fragment>
            <Typography variant="h1" gutterBottom style={{ textAlign: "left", fontSize: 12, marginBottom: 0, fontFamily: 'Lato' }}>
              DASN - {ano}
            </Typography>
            <Typography variant="h4" gutterBottom style={{ textAlign: "left", fontSize: 23, marginBottom: 10, fontFamily: 'Lato' }}>
              Cartão de crédito
            </Typography>
            <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", fontFamily: 'Lato', marginBottom: 30 }}>
              Após realizar o pagamento, em até 2 dias úteis você receberá seu comprovante da declaração no e-mail e WhatsApp que foi cadastrado em nossa plataforma.
            </Typography>
            <Grid item xs={24} md={24} lg={24} xl={24}>
              <Card
                sx={{
                  border: '1px solid #99999944'
                }}
              >
                <CardContent>
                  <CardCredit onConfirmation={onConfirmationCredit} loading={loadingCreatePaymentsCredit} />
                  {buttonUpdateFormPayment()}
                </CardContent>
              </Card>
            </Grid>
          </React.Fragment>
        )}

        {step === 5 && (
          <React.Fragment>
            <Typography variant="h4" gutterBottom style={{ textAlign: "center", fontSize: 23, marginBottom: 15, fontFamily: 'Lato' }}>
              Pagamento realizado com sucesso
            </Typography>

            <CheckCircle size={100} color="green" />

            <Typography variant="h6" gutterBottom style={{ fontSize: 14, textAlign: "left", fontFamily: 'Lato', marginBottom: 30, marginTop: 20 }}>
              Seu pagamento foi confirmado com sucesso! Estamos agora iniciando a transmissão da sua declaração anual e enviaremos o comprovante para você por e-mail e WhatsApp em breve.
            </Typography>

            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{ marginBottom: 2, marginTop: 2, borderRadius: 4 }}
              type="submit"
              style={{ fontFamily: 'Lato', backgroundColor: COLORS.amareloServemei }}
              onClick={() => {
                setIdPix(null);
                window.location.replace("/regularizacao")
              }}
            >
              Acompanhar Progresso
            </Button>
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{ marginBottom: 2, marginTop: 0, borderRadius: 4 }}
              type="submit"
              style={{ fontFamily: 'Lato', backgroundColor: COLORS.white, color: COLORS.servemei, border: `1px solid ${COLORS.servemei}` }}
              onClick={() => {
                setIdPix(null);
                window.location.href = '/'
                localStorage.removeItem('@servemei:ano:regularizacao');
                localStorage.removeItem('@servemei:regularização');
              }}
            >
              Voltar ao inicio
            </Button>
          </React.Fragment>
        )}
      </Container>
      {renderFooterV2()}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        message={messageError}
        key={"top" + "left"}
      />

      {loadingCreatePaymentsCredit && (
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ position: "relative" }}>
            <CircularProgress />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}