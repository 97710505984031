import { Typography } from "@mui/material";
import { styled } from "styled-components"

export const HeaderContainer = styled.header`
  border-bottom: 1px solid #e4e4e4;
  box-shadow: 5px 2px 9px 1px #C1C3C5;
  width: 100%;
  height: 5rem; 
  margin-bottom: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding-inline: 5rem;

  > img {
      width: 120px;
      margin-bottom: 8px;
    }

  @media (max-width: 900px) {
    padding-inline: 1rem;

     > img {
      width: 100px;
    }
  }
`

export const InfoCnpj = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: "Lato";

  > span {
    background: ${(props)=> props.color};
    border-radius: 8px;
    padding: 0.5rem 1rem;
    color: white;
    font-weight: bold;
  }

  @media (max-width: 900px) {
  //  display: none;
  }
`;

export const TextStatus = styled(Typography).attrs({
  fontSize: "14px"
})`
  font-family: "Lato";
  font-weight: 700;

  @media (max-width: 900px) {
   // font-size: 1px;
    //display: none;
  }
`;

export const TextName = styled(Typography).attrs({
  borderRight: "1px solid #dcd6d9",
  paddingRight: 1,
  fontSize: "14px"
})`
  font-family: "Lato";
  font-weight: 400;
  font-size: 0.85rem;

  @media (max-width: 950px) {
    display: none;
  }
`;