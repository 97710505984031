import { styled } from "styled-components";

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin-bottom: 1rem;
  flex-direction: column;

  @media (max-width: 900px) {
    gap: 1rem;
  }
`

export const ContainerName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 1.5rem;
  gap: 0.3rem;

   @media (max-width: 900px) {
     gap: 0rem;
  }
`

export const ContainerButton = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin-right: 5px;

  @media (max-width: 900px) {
    gap: 1rem;
  }

    > span {
    color: #fff;
    font-size: 0.75rem;
    border-radius: 20px;
    padding: 0.5rem;
    font-weight: bold;
    background-color: ${(props) => props.color ? props.color : "#fdf5f4"} ;
    font-family: "Lato";
    min-width: 120px;
  }
`
export const InfoContent = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border: 1px solid #99999955;
  margin-bottom: 1px;
  align-items: center;
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 8px;
`
export const ButtonLink = styled.button`
  background-color: transparent;
  border: none;
  color: #f97066;
  cursor: pointer;
`