import { useMutation } from '@tanstack/react-query';
import api from '../../service/api';
import queryClient from '../queryClient';
export const LIST_DECLARATION_YEARS = "LIST_DECLARATION_YEARS"
export const LIST_YEARS = "LIST_YEARS"

export function useAnnualDeclarationYears() {
  const mutationFn = async (cnpj: string | null) => {
    if (!cnpj) {
      return null;
    }

    const cnpjUnformat = cnpj.replace(/\D/g, '')
    const { data } = await api.get(`/yearlyDeclaring/landPage/${cnpjUnformat}`);
    queryClient.setQueryData([LIST_YEARS], () => [...data]);
    return data
  };

  return useMutation({
    mutationKey: [LIST_DECLARATION_YEARS],
    mutationFn,
  });
}
