import { useMutation } from "@tanstack/react-query";
import { ApiError, MutationResponse, PaymentDataCredit } from "./types";
import api from '../../service/api';
import queryClient from "../queryClient";
import { LIST_DECLARATION_YEARS } from "./useAnnualDeclarationYears";

export const FINISH_DO_PAYMENT_CREDIT = "FINISH_DO_PAYMENT_CREDIT"

export function useDoPaymentCredit() {
  const mutationFn = async (paymentData: PaymentDataCredit): Promise<string> => {
    try {
      await api.post<MutationResponse>('/yearlyDeclaring/creditePayment/create',
        {
          ...paymentData,
          cardCredit: paymentData.cardCredit.cardCredit
        });

      return "success";
    } catch (error) {
      throw error as ApiError;
    }
  };

  return useMutation<string, ApiError, PaymentDataCredit>({
    mutationKey: [FINISH_DO_PAYMENT_CREDIT],
    mutationFn,
    onSettled: () => {
      queryClient.invalidateQueries([LIST_DECLARATION_YEARS]);
    },
  });
}