import axios from 'axios';
import { endpoints } from '../../env'
import { SESSION_TOKEN } from '../utils/constants';

const source = axios.CancelToken.source();

const api = axios.create({
  baseURL: endpoints.api,
  cancelToken: source.token,
});

api.interceptors.request.use(async (config) => {
  config.headers.authorization = `Bearer ${SESSION_TOKEN}`;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        //  console.log(error?.response?.status);
      }
    }
    return Promise.reject(error);
  },
);

export default api;
