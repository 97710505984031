export const COLORS = {
  primary: '#24C16B', // green
  secondary: '#0C381F', // dark green

  green: '#66D59A',
  lightGreen: '#E6FEF0',

  lime: '#00BA63',
  emerald: '#2BC978',

  red: '#FF4134',
  redNew: '#FF4E4E',
  lightRed: '#FFF1F0',

  purple: '#6B3CE9',
  lightpurple: '#F3EFFF',

  yellow: '#FFC664',
  lightyellow: '#FFF9EC',

  black: '#1E1F20',
  white: '#FFFFFF',

  lightGray: '#FCFBFC',
  gray: '#C1C3C5',
  darkgray: '#C3C6C7',
  servemeiclaro: '#BBE0EB',

  servemei: '#0593d2',
  amareloServemei: '#edc42e',
  cinzaServemei: '#30374a',
  verdeClaro: '#059305',
  vermelhoClaro: '#F65F5F',
  orangeServemei: 'orange',
  transparent: 'transparent',

  stausPendente: 'orange',
  stausVencido: 'red',
  stausPago: 'green',
};