import { COLORS } from "../../themes/colors";

const Timeline = ({step}: {step: number}) => {
  return (
    <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
    <div style={{ textAlign: 'center', marginBottom: 30, flexDirection: "row", display: "flex", height: 12, borderRadius: 3, backgroundColor: step === 1 ? COLORS.servemei : "#99999955", width: "24%" }} />
    <div style={{ textAlign: 'center', marginBottom: 30, flexDirection: "row", display: "flex", height: 12, borderRadius: 3, backgroundColor: step === 2 ? COLORS.servemei : "#99999955", width: "24%" }} />
    <div style={{ textAlign: 'center', marginBottom: 30, flexDirection: "row", display: "flex", height: 12, borderRadius: 3, backgroundColor: step === 3 ? COLORS.servemei : step === 4 ? COLORS.servemei : "#99999955", width: "24%" }} />
    <div style={{ textAlign: 'center', marginBottom: 30, flexDirection: "row", display: "flex", height: 12, borderRadius: 3, backgroundColor: step === 5 ? COLORS.servemei : "#99999955", width: "24%" }} />
  </div>
  )
}

export default Timeline;