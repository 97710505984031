/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const Countdow = ({ isActive, setStep }: { isActive: boolean, setStep: (value: number) => void }) => {
  const [seconds, setSeconds] = useState(300)

  const countdown = () => {
    if (isActive && seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      setStep(2)
    }
  }

  useEffect(() => {
    if (isActive) {
      countdown()
    }
  }, [seconds, isActive])

  useEffect(() => {
    if (isActive) {
      setSeconds(300)
    }
  }, [isActive])

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  return (
    <Typography variant="h4" gutterBottom style={{ textAlign: "center", fontFamily: 'Lato', marginBottom: 20, marginTop: 2, fontWeight: "bold" }}>
      {`${minutes.toString().padStart(2, '0')}:${remainingSeconds
        .toString()
        .padStart(2, '0')}`}
    </Typography>
  )
}

export default Countdow;