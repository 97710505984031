import { useQuery } from '@tanstack/react-query';
import api from '../../service/api';
import { LIST_DECLARATION_YEARS } from './useAnnualDeclarationYears';
import queryClient from '../queryClient';

export const GET_PIX_CONFIRMATION = "GET_PIX_CONFIRMATION"

export function useGetPixConfirmation(idPix: number | null) {
    const getServerList = async () => {
      const { data } = await api.get(`/services/pix/payments/${idPix}`);
      return data;
    };

    return useQuery([GET_PIX_CONFIRMATION, idPix], getServerList, {
      refetchInterval: 400, // 400ms
      enabled: !!idPix,
      onSettled: (data) => {
        if (data.status === 'Pago') {
          queryClient.invalidateQueries([LIST_DECLARATION_YEARS]);
        }
      }
    });
  }
