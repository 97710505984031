export const cnpjMask = (text: string) => {
  return text
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const formatPrice = (price: number) =>
  price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace('.', ',')


  export function maskValue(i: string) {
    let v = i.replace(/\D/g, '');
    v = (Number(v) / 100).toFixed(2) + '';
    v = v.replace('.', ',');
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return `R$ ${v}`;
  }

  export function mcc(v: string) {
    v = v.replace(/\D/g, '') // Permite apenas dígitos
    v = v.replace(/(\d{4})/g, '$1.') // Coloca um ponto a cada 4 caracteres
    v = v.replace(/\.$/, '') // Remove o ponto se estiver sobrando
    v = v.substring(0, 19) // Limita o tamanho
  
    return v
  }

  export function formatExpiry(v: string) {
    v = v.replace(/\D/g, '')
    v = v.replace(/(\d{2})(\d)/, '$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1.$2')
  
    return v
  }

  export const formatCPF = (cpf: string) => {
    const cpfFormatted = cpf.replace(/[^\d]/g, '')
    return cpfFormatted.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  export function formatPhone(text: string) {
    if (text.length === 14) {
      const onlyNumber = text.replace(/\D/g, '');
      return text
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(
          onlyNumber.length >= 10 ? /(\d{4})(\d)/ : /(\d{4})(\d)/,
          '$1-$2',
        );
    } else {
      const onlyNumber = text.replace(/\D/g, '');
      return text
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(
          onlyNumber.length >= 11 ? /(\d{5})(\d)/ : /(\d{4})(\d)/,
          '$1-$2',
        );
    }
  }