import { Suspense } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Home } from "./pages/home/home.screen";
import { Result } from "./pages/result/result.screen";
import { Payment } from "./pages/payments/payments.screen";

export function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-K6TRR5P'
  }

  TagManager.initialize(tagManagerArgs)

  return (
    <BrowserRouter>
      <Suspense fallback={false}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mei" element={<Home />} />
          <Route path="/regularizacao" element={<Result />} />
          <Route path="/payments" element={<Payment />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

