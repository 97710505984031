import { HeaderContainer, InfoCnpj, TextName, TextStatus } from './styles';
import logoServemei from "../../assets/Logo-Servemei.png";
import useYears from '../../store/annualDeclaration/useYears';
import { cnpjMask } from '../../utils/format';

interface propsHeader {
  loading: boolean
}

const Header = (params: propsHeader) => {
  const { loading } = params

  const {
    data: annualDeclarationYears,
  } = useYears();

  return (
    <HeaderContainer>
      <img src={logoServemei} alt="logo" />

      {!loading && (
        <InfoCnpj color={annualDeclarationYears?.user?.situacaoCadastral === "OK" ? "#12b76a" : "red"}>
          <TextName>
            {annualDeclarationYears?.user?.nomeEmpresarial ? annualDeclarationYears?.user?.nomeEmpresarial : "Nome da Empresa"}
          </TextName>

          <TextStatus>{cnpjMask(annualDeclarationYears?.user?.cnpj || "") || ""}</TextStatus>
          <span>{annualDeclarationYears?.user?.situacaoCadastral === "OK" ? "ATIVA" : "INATIVA"}</span>
        </InfoCnpj>
      )}
    </HeaderContainer>
  )
}

export default Header;