import {
  CoverContainer,
  HelpContainer,
  PageContainer,
  ResultClient,
  TextContainer,
  WrapperContainer,
  TypographyTitle
} from "./styles";

import { Warning } from "phosphor-react";
import { Typography } from "@mui/material";
import { InfoPendencia } from "../../components/InfoPendencia";
import ButtonWhatsapp from "../../components/ButtonWhatsapp";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import { useEffect, useState } from "react";
import CircularIndeterminate from "../../components/loading/loading";
import React from "react";
import useYears from "../../store/annualDeclaration/useYears";

export function Result() {
  const [loading, setLoading] = useState(true);

  const {
    data: annualDeclarationYears,
  } = useYears();

  useEffect(() => {
    const isLoggin = () => {
      const getCnpj = localStorage.getItem('@servemei:regularização');

      if (!getCnpj) {
        window.location.href = '/'
        return
      }

      if (!annualDeclarationYears?.user?.cnpj) {
        window.location.href = '/'
        return
      }

      setLoading(false)
    }

    isLoggin()
  }, [annualDeclarationYears])

  const isPendency = annualDeclarationYears?.years?.filter((item: { transmitida: boolean }) => item.transmitida === false) || []

  const isLoading = loading

  if (isLoading) {
    return (
      <React.Fragment>
        <Header loading={isLoading} />
        <CircularIndeterminate />
      </React.Fragment>
    )
  }

  return (
    <>
      <Header loading={isLoading} />
      <PageContainer>
        <CoverContainer>
          <span>
            <Warning weight="fill" size={20} />
            <Typography fontWeight="700" style={{ fontFamily: 'Lato' }}>Resultado</Typography>
          </span>
          {isPendency?.length > 0 ? (
            <TypographyTitle>
              Você possui {isPendency?.length} declaração <br /> pendente
            </TypographyTitle>
          ) : (
            <TypographyTitle>
              Parabéns. Você está em dia com suas <br /> declarações
            </TypographyTitle>
          )}

          {isPendency?.length > 0 ? (
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato' }}>
              Selecione o ano que deseja declarar
            </Typography>
          ) : (
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato' }}>
              Você está em dia com suas declarações
            </Typography>
          )}
        </CoverContainer>
        <WrapperContainer>
          <ResultClient>
            <InfoPendencia loading={isLoading} />
          </ResultClient>
          <HelpContainer>
            <TextContainer>
              <Typography variant="h6" style={{ fontFamily: 'Lato' }}>
                Como a ServeMei pode me ajudar?
              </Typography>
              <Typography style={{ fontFamily: 'Lato', fontSize: 13 }}>
                Nós resolvemos os processos burocráticos das suas obrigações
                como MEI de um jeito simples, rápido e seguro.<br />
                <br />É possível fazer tudo isso sozinho e gratuitamente pelo
                site do governo, mas com o ServeMei você tem a comodidade e a
                certeza de que está tudo certo com seu CNPJ MEI, graças ao nosso
                time de especialistas.
              </Typography>
            </TextContainer>
            <div>
              <img
                src="https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_1352/https://servemei.com.br/wp-content/uploads/2023/06/Mockup-ServeMEI.webp"
                alt=""
              />
            </div>
          </HelpContainer>
        </WrapperContainer>
        <ButtonWhatsapp />
      </PageContainer>
      <Footer />
    </>
  );
}
